import React from "react";
import "./index.scss";

type Props = {
  description: string;
  icon: React.ReactNode;
  color?: string;
};

const VisionCard: React.FC<Props> = (props) => {
  return (
    <div className="vision-card-wrapper">
      <div style={{ color: props.color || '#0099FF' }} className="icon">{props.icon}</div>
      <hr />
      <div className="description">{props.description}</div>
    </div>
  );
};

export default VisionCard;
