import React from 'react';
import Container from '../Container';
import './index.scss';

type Props = {
  id?: string
}

const TitleContainer: React.FC<Props> = props => {
  return <Container className="title-container">
      <span className="title" id={props.id}>{ props.children }</span>
      <div className="bottom-bar" />
  </Container>
}

export default TitleContainer;