import React from 'react';
import neoenergiaLogo from '../../assets/neoenergia-logo.png';
import engelmigLogo from '../../assets/engelmig-logo.png';
import energisaLogo from '../../assets/energisa-logo.png';
import biocampLogo from '../../assets/biocamp-logo.png';
import './index.scss';

const ClientBar: React.FC = () => {
  return <ul id="about-us" className="clients-wrapper">
      <li><img src={neoenergiaLogo} alt="Neoenergia" /></li>
      <li><img src={engelmigLogo} alt="Emgelmig" /></li>
      <li><img src={energisaLogo} alt="Energisa" /></li>
      <li><img src={biocampLogo} alt="Biocamp" /></li>
  </ul>;
}

export default ClientBar;