import React from "react";
import {
  Banner,
  ClientBar,
  Footer,
  FullImageContainer,
  Header,
  ImageTextContainer,
  ServiceComponent,
  SubtitleContainer,
  TitleContainer,
} from "../../components";
import {
  FaUsers,
  FaBalanceScale,
  FaHandshake,
  FaRecycle,
  FaLeaf,
  FaChevronCircleRight,
  FaChevronCircleLeft,
  FaSuitcase,
  FaRocket,
} from "react-icons/fa";
import { FaPeopleGroup, FaShieldHeart } from "react-icons/fa6";
import Slider from "react-slick";
import "./index.scss";
import VisionCard from "../../components/VisionCard";
import ColoredContainer from "../../components/ColoredContainer";

import ImageAboutUs3 from "../../assets/about-us-3.png";

import ImageService1 from "../../assets/service-1.png";
import ImageService2 from "../../assets/service-2.jpg";
import ImageService3 from "../../assets/service-3.jpg";
import ImageService4 from "../../assets/service-4.png";
import ImageService5 from "../../assets/service-5.jpg";
import ImageService6 from "../../assets/service-6.jpeg";

const Home: React.FC = () => {
  const handleApplyButtonClick = () => {
    window.open(
      "https://consolis.nydusrh.com/curriculo/default.aspx?Assinatura=204"
    );
  };

  const handleContactButtonClick = () => {
    window.open("https://forms.gle/93ciScZXy3kvxQZV7");
  };

  return (
    <div>
      <Header active="Início" />
      <Banner />
      <ClientBar />
      <FullImageContainer normal title="Nossa História">
        A CONSOLIS SOLUÇÕES INTELIGENTES LTDA foi criada em 2009 por
        profissionais com mais de 20 anos em serviços comerciais (leitura de
        medidores, entrega de faturas e correspondências, suspensão de
        fornecimento de energia elétrica e religação, além de inspeção de
        unidades consumidoras) na cidade de Valinhos, São Paulo. Com o objetivo
        de inovar na área de prestação de serviços no setor de energia elétrica,
        a Consolis já se encontra a {new Date().getFullYear() - 2009} anos
        atuando com alta qualidade e excelência.
        <button onClick={handleApplyButtonClick}>Faça Parte da Equipe!</button>
      </FullImageContainer>
      <ImageTextContainer inverse enableShadow title="Nosso Objetivo">
        O objetivo da empresa, em seu início, foi o desenvolvimento de soluções
        para a melhoria da qualidade de serviços prestados nos setores de
        distribuição de energia elétrica. A partir de 2017, passamos a focar nas
        atividades de prestação de serviços em leitura de medidores, corte,
        religação, troca de medidor e serviços comerciais. A aposta dos clientes
        na CONSOLIS rendeu ótimos resultados e, principalmente, elevou a
        qualidade dos serviços comerciais. Hoje, através da qualidade e
        excelência do serviço prestado, buscamos ser referência em nosso
        segmento.
      </ImageTextContainer>
      <FullImageContainer
        image={ImageService1}
        title="Nossas Atividades"
        normal
      >
        Nossas atividades estão voltadas principalmente para coleta de
        informações em campo, utilizando os melhores recursos e tecnologias para
        aumentar a eficiência do serviço prestado e, consequentemente, impactar
        positivamente as operações de nossos clientes e funcionários.
        <button onClick={() => window.open("#our-services", "_self")}>
          Quero Saber Mais
        </button>
      </FullImageContainer>
      <ImageTextContainer image={ImageAboutUs3} enableShadow inverse>
        Somos movidos pela oportunidade de criar soluções inovadoras e
        impulsionar a excelência a fim de transformar positivamente a
        experiência de nossos clientes internos e externos. Juntos, acreditamos
        que a valorização de nossos colaboradores e parceiros e um trabalho
        focado em qualidade são fundamentais para alcançar e propagar nosso
        propósito.
      </ImageTextContainer>
      <ColoredContainer color="#00497a" title="Nossos Princípios e Valores">
        <Slider
          responsive={[
            {
              breakpoint: 1500,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
          className="values-slider"
          infinite
          slidesToShow={3}
        >
          <VisionCard
            color="#2980b9"
            icon={<FaPeopleGroup />}
            description="Valorização das Pessoas"
          />
          <VisionCard
            color="#27ae60"
            icon={<FaLeaf />}
            description="Sustentabilidade"
          />
          <VisionCard
            color="#16a085"
            icon={<FaHandshake />}
            description="Integridade e Ética"
          />
          <VisionCard
            color="#e74c3c"
            icon={<FaShieldHeart />}
            description="Respeito à Vida com Foco em Segurança"
          />
          <VisionCard
            color="#006266"
            icon={<FaRecycle />}
            description="Foco em Resultados Sustentáveis"
          />
          <VisionCard
            color="#0984e3"
            icon={<FaSuitcase />}
            description="Intra-empreendedorismo"
          />
          <VisionCard
            color="#6ab04c"
            icon={<FaRocket />}
            description="Inovação"
          />
        </Slider>
      </ColoredContainer>

      <ImageTextContainer
        enableShadow
        title="Código de Ética e Conduta"
        inverse
        color="#007427"
        icon={<FaUsers />}
      >
        Apresentamos aqui nosso código de ética e conduta, ao qual todos os
        nossos colaboradores devem ter conhecimento de cada linha e detalhe do
        mesmo. Este código garante transparência e simplicidade aos serviços
        prestados pela CONSOLIS e todo o time que nela trabalha. Nosso Código
        cria uma conexão entre a nossa missão, a nossa visão e principalmente,
        os nossos valores. Reflete de maneira simples e clara o que devemos
        fazer e contribuir individual e coletivamente para que nossos principais
        objetivos sejam alcançados. Nele, também cumprimos todas as legislações
        vigentes.
        <br />
        <button
          onClick={() =>
            window.open("/Código de Ética e Conduta - CONSOLIS.pdf", "_blank")
          }
        >
          Acessar o Documento
        </button>
      </ImageTextContainer>

      <TitleContainer id="our-services">Nossos Serviços</TitleContainer>
      <Slider
        responsive={[
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
        nextArrow={<FaChevronCircleRight color="#005288" />}
        prevArrow={<FaChevronCircleLeft color="#005288" />}
        slidesToScroll={3}
        infinite
        dots
        slidesToShow={3}
        className="services-list"
      >
        <ServiceComponent
          image={ImageService1}
          title="Leitura de Medidores"
          description="Leitura de Medidores Urbano e Rural com e sem emissão de fatura de energia elétrica."
        />
        <ServiceComponent
          image={ImageService2}
          title="Serviços Comerciais"
          description="Atividades de suspensão de fornecimento de energia elétrica, religação, ligação de novos consumidores e inspeção em unidades de consumo, urbano e rural."
        />
        <ServiceComponent
          image={ImageService3}
          title="Desenvolvimento de Soluções"
          description="Soluções para coleta de dados, sistema GIS, gestão de serviços de leitura de medidores, iluminação pública e uso mútuo."
        />
        <ServiceComponent
          image={ImageService4}
          title="Entrega de Correspondências"
          description="Entrega de correspondências urbano e rural e entrega de reavisos de vencimento de fatura de energia elétrica."
        />
        <ServiceComponent
          image={ImageService5}
          title="Reavisos de Vencimento"
          description="Conta com o sistema de emissão de reaviso desenvolvido pela CONSOLIS. Atividades realizadas de moto ou bicicleta."
        />
        <ServiceComponent
          image={ImageService6}
          title="Consultorias e Projetos"
          description="Consultorias e projetos em eficiência energética para clientes do Grupo A e B e, consultorias em gestão de projetos."
        />
      </Slider>

      <ImageTextContainer
        enableShadow
        title="Divulgação do Relatório de Transparência Salarial"
        color="#0099FF"
        icon={<FaBalanceScale />}
      >
        Este relatório de transparência salarial é uma exigência do Ministério
        do Trabalho e Emprego (MTE) e foi elaborado por essa instituição. A
        CONSOLIS está apenas cumprindo a determinação legal ao REPUBLICAR esses
        dados em nossos canais de comunicação.
        <span style={{ marginTop: 10, display: "block" }}>
          Data de Publicação:{" "}
          <span style={{ fontStyle: "italic" }}>29/03/2024</span>
        </span>
        <button onClick={() => window.open("/transparencia-salarial", "_self")}>
          Acessar o Relatório
        </button>
      </ImageTextContainer>
      <FullImageContainer
        videoUrl="https://www.youtube.com/embed/bMEJPE6fH_U"
        normal
        title="Faça Parte da Equipe"
      >
        Gostou do que viu? Venha fazer parte da família Consolis! Clique no
        botão abaixo para enviar seu currículo e entrar para o nosso banco de
        talentos!
        <button onClick={handleApplyButtonClick} className="secondary center">
          Quero Enviar Meu Currículo!
        </button>
      </FullImageContainer>
      <ColoredContainer
        style={{ marginTop: 0, marginBottom: 0 }}
        id="contact-form"
        color="#00385d"
        title="Entre em Contato"
      >
        <SubtitleContainer color="white">
          Clique no botão abaixo para acessar o formulário de contato. Você pode
          submeter um comentário, solicitar informações ou até mesmo registrar
          uma ocorrência. Respondemos com velocidade, por isso, não hesite em
          nos contactar!
        </SubtitleContainer>
        <button
          onClick={handleContactButtonClick}
          className="center secondary"
          style={{ margin: "5px auto 0px" }}
        >
          Acessar Formulário
        </button>
      </ColoredContainer>

      <Footer />
    </div>
  );
};

export default Home;
