import React from 'react';
import {
  Footer,
  Header,
  SubtitleContainer,
  TitleContainer,
} from '../../components';
import img1 from '../../assets/transparencia-salarial-1.png';
import img2 from '../../assets/transparencia-salarial-2.png';
import './index.scss';

const SalaryDocument: React.FC = () => {
  return (
    <div>
      <Header active="Transparência Salarial" />
      <TitleContainer>Transparência Salarial</TitleContainer>
      <SubtitleContainer>
        Este relatório de transparência salarial é uma exigência do Ministério
        do Trabalho e Emprego (MTE) e foi elaborado por essa instituição. A
        CONSOLIS está apenas cumprindo a determinação legal ao REPUBLICAR esses
        dados em nossos canais de comunicação.
        <span style={{ marginTop: 10, display: 'block' }}>
          Data de Publicação:{' '}
          <span
            style={{ fontStyle: 'italic' }}>
            29/03/2024
          </span>
        </span>
      </SubtitleContainer>

      <img
        src={img1}
        alt="Transparência Salarial 1"
        className="wage-transparency-image"
      />
      <hr />
      <img
        src={img2}
        alt="Transparência Salarial 2"
        className="wage-transparency-image"
      />

      <Footer />
    </div>
  );
};

export default SalaryDocument;
