import React from "react";
import { Container } from "..";
import "./index.scss";

type SubtitleContainerProps = {
  color?: string;
};

const SubtitleContainer: React.FC<SubtitleContainerProps> = (props) => {
  return (
    <Container className="subtitle-container">
      <span style={{ color: props.color || "black" }} className="text">
        {props.children}
      </span>
    </Container>
  );
};

export default SubtitleContainer;
