import React from "react";
import { FaFacebook, FaLinkedinIn } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Container } from "..";
import "./index.scss";

const Footer: React.FC = () => {
  return (
    <Container className="footer">
      <ul className="social-medias">
        <li
          onClick={() =>
            window.open(
              "https://www.facebook.com/profile.php?id=100057228408711"
            )
          }
        >
          <FaFacebook />
        </li>
        <li
          onClick={() =>
            window.open(
              "https://www.linkedin.com/company/consolis-solu-es-inteligentes"
            )
          }
        >
          <FaLinkedinIn />
        </li>
        <li
          onClick={() =>
            window.open(
              "https://www.instagram.com/consolissoli/"
            )
          }
        >
          <AiFillInstagram />
        </li>
      </ul>
      <span className="copyright-info">
        © Copyright {new Date().getFullYear()} - Consolis Soluções Inteligentes
        LTDA
      </span>
    </Container>
  );
};

export default Footer;
