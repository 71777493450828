import React from "react";
import { Container } from "..";
import "./index.scss";

type Props = {
  title: string;
  inverse?: boolean;
  image?: string;
  normal?: boolean;
  color?: string;
  icon?: JSX.Element;
  center?: boolean;
  videoUrl?: string;
};

const FullImageContainer: React.FC<Props> = (props) => {
  let image = props.image ? `url("${props.image}")` : "";
  if (props.icon) image = `${props.color}`;
  const imageStyle = props.image
    ? { backgroundImage: image }
    : { background: image };

  const imageDiv = (
    <div
      className={`image-wrapper${props.icon ? " icon" : ""}`}
      style={imageStyle}
    >
      {props.icon}
    </div>
  );

  const videoDiv = (
    <iframe src={props.videoUrl} title="Video" />
  )

  const textDiv = (
    <div className="text-wrapper">
      <span className="title">{props.title}</span>
      <div className="separator" />
      <span className="description">{props.children}</span>
    </div>
  );

  const mediaDiv = props.videoUrl ? videoDiv : imageDiv;

  return (
    <Container
      type="alt"
      className={`full-image-container${props.inverse ? " inverse" : ""}${
        props.normal ? " normal" : ""
      }${props.center ? " center" : ""}`}
    >
      <div className="image-text-item">
        {props.inverse ? mediaDiv : textDiv}
        {props.inverse ? textDiv : mediaDiv}
      </div>
    </Container>
  );
};

export default FullImageContainer;
