import React from 'react';
import ReactDOM from 'react-dom';
import Home from './pages/Home';
import Complaints from './pages/Complaints';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import SalaryDocument from './pages/SalaryDocument';

import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route exact path="/denuncias">
        <Complaints />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/transparencia-salarial">
        <SalaryDocument />
      </Route>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);