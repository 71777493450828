import axios from 'axios';

const HOST = 'https://api.consolis.glowth.app';

const api = {
    mail: {
        async send (payload: { body: string, name: string, email: string, phone: string, type: string }) {
            return axios.post(`${HOST}/mail`, payload)
        }
    }
}

export default api;