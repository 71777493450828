import React from 'react';
import './index.scss';

const Banner: React.FC = () => {
  return <div className="banner-wrapper">
    <div className="shadow-bar" />

    <div className="banner-info">
      <span className="banner-title">UMA NOVA VISÃO EM SERVIÇOS TERCEIRIZADOS</span>
      <span className="banner-description">Uma empresa com foco nas necessidades do cliente e um sistema forte na administração e operação.</span>
      <button onClick={() => window.open('#about-us', '_self')}>Saiba Mais</button>
    </div>
  </div>;
}

export default Banner;