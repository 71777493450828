import React from "react";
import {
  Footer,
  Header,
  SectionBanner,
  SubtitleContainer,
  TitleContainer,
} from "../../components";
import "./index.scss";

const Complaints: React.FC = () => {
  const handleContactButtonClick = () => {
    window.open(
      "https://forms.gle/93ciScZXy3kvxQZV7"
    );
  };

  return (
    <div>
      <Header active="Canal de Denúncias" />
      <SectionBanner>Serviço de Atendimento</SectionBanner>
      <TitleContainer>Registrar uma Denúncia</TitleContainer>
      <SubtitleContainer>
        Valorizamos 100% seu Feedback. Clique no botão abaixo para acessar o
        formulário e registrar uma denúncia. Retornaremos o quanto antes a fim
        de resolver o problema!
      </SubtitleContainer>
      <button
        onClick={handleContactButtonClick}
        className="center"
        style={{ margin: "5px auto 30px" }}
      >
        Acessar Formulário
      </button>
      <Footer />
    </div>
  );
};

export default Complaints;
