import React from "react";
import { useHistory } from "react-router-dom";
import logoImage from "../../assets/logo.png";
import "./index.scss";

type Props = {
  active?: string;
};

const Header: React.FC<Props> = (props) => {
  const menuEntries: { name: string; path: string; special?: boolean }[] = [
    {
      name: "Transparência Salarial",
      path: "/transparencia-salarial",
    },
    {
      name: "Canal de Denúncias",
      path: "/denuncias",
    },
    {
      name: "Início",
      path: "/",
    },
  ];

  const history = useHistory();

  return (
    <div className="header-wrapper">
      <div className="header">
        <ul className="header-items">
          <li className="logo">
            <img src={logoImage} alt="logo" />
          </li>
          {menuEntries.map((entry) => (
            <li
              onClick={() => {
                if (entry.path && entry.path[0] === "/")
                  history.push(entry.path);
                else if (entry.path && entry.path[0] === "#")
                  window.open(entry.path, "_self");
              }}
              className={`${entry.name === props.active ? "active" : ""}${
                entry.special ? " special" : ""
              }`}
            >
              {entry.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="header-block" />
    </div>
  );
};

export default Header;
