import React from "react";
import { Container } from "..";
import "./index.scss";

type Props = {
  title?: string;
  inverse?: boolean;
  image?: string;
  normal?: boolean;
  color?: string;
  icon?: JSX.Element;
  center?: boolean;
  enableShadow?: boolean;
};

const ImageTextContainer: React.FC<Props> = (props) => {
  let image = props.image ? `url("${props.image}")` : "";
  if (props.icon) image = `${props.color}`;
  const imageStyle = props.image
    ? { backgroundImage: image }
    : { background: image };

  const imageDiv = (
    <div
      className={`image-wrapper${props.icon ? " icon" : ""}`}
      style={imageStyle}
    >
      {props.icon}
    </div>
  );

  const textDiv = (
    <div className="text-wrapper">
      {props.title && (
        <>
          <span className="title">{props.title}</span>
          <div className="separator" />
        </>
      )}
      <span className="description">{props.children}</span>
    </div>
  );

  return (
    <Container
      type="alt"
      className={`image-text-container${props.inverse ? " inverse" : ""}${
        props.normal ? " normal" : ""
      }${props.center ? " center" : ""}`}
      enableShadow={props.enableShadow}
    >
      <div className="corner-1" />
      <div className="corner-2" />
      <div className="image-text-item">
        {props.inverse ? imageDiv : textDiv}
        {props.inverse ? textDiv : imageDiv}
      </div>
    </Container>
  );
};

export default ImageTextContainer;
