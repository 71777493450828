import React, { CSSProperties } from "react";
import { Container } from "..";
import "./index.scss";

type Props = {
  title: string;
  color?: string;
  icon?: JSX.Element;
  id?: string;
  style?: CSSProperties;
};

const ColoredContainer: React.FC<Props> = (props) => {
  return (
    <Container style={{ backgroundColor: props.color || 'white', ...props.style }} type="alt" className="colored-container">
      <div id={props.id} className="text-wrapper">
        <span className="title">{props.title}</span>
        <div className="separator" />
        {props.children}
      </div>
    </Container>
  );
};

export default ColoredContainer;
