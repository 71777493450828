import React from "react";
import "./index.scss";

type Props = {
  className?: string;
  type?: "alt" | "normal";
  enableShadow?: boolean;
  style?: React.CSSProperties;
};

const Container: React.FC<Props> = (props) => {
  return (
    <div
      style={{ boxShadow: props.enableShadow ? undefined : 'unset', zIndex: props.enableShadow ? 10 : 0, ...props.style }}
      className={`container-wrapper${
        props.className ? ` ${props.className}` : ""
      } ${props.type ? props.type : "normal"}`}
    >
      {props.children}
    </div>
  );
};

export default Container;
