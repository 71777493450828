import React from "react";
import "./index.scss";

type Props = {
  image: string;
  title: string;
  description: string;
};

const ServiceComponent: React.FC<Props> = (props) => {
  return (
    <li className="service-component">
      <div
        className="image"
        style={{ backgroundImage: props.image ? `url('${props.image}')` : "" }}
      />
      <div className="info">
        <span className="title">{props.title}</span>
        <div className="separator" />
        <span className="description">{props.description}</span>
      </div>
    </li>
  );
};

export default ServiceComponent;
